import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap";
import VueSlickCarousel from "vue-slick-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import ImageUploader from "vue-image-upload-resize";
import VueExpandableImage from "vue-expandable-image";
import wb from "./registerServiceWorker";
//socket io
// import * as io from "socket.io-client";
// import VueSocketIO from "vue-socket.io";
//
import "@/assets/css/style.css";
//
// Vue.use(
//     new VueSocketIO({
//       debug: true,
//       connection: io('http://172.17.68.161:2020/'), //options object is Optional
//     })
// );

Vue.use(ImageUploader);
Vue.use(VueExpandableImage);
Vue.use(VueSlickCarousel);
Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

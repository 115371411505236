<template>
    <div class="container ">
        <div class="row p-0  ">
            <div class="col-12  col-md-12 px-0 mx-0 img-back" style="min-height: 40vh;">
                <div>
                    <img src="../assets/camera-logo.png"
                         class="icon-camera img-fluid px-0"
                         alt=""/>
                    <h2 class="font-22 px-0 text-white">Aplikasi Foto Display</h2>
                    <p class="mt-2 spacingfont font-12 px-0 text-white">
                        Aplikasi untuk foto display rak <br/>
                        yang ada di outlet setiap bulan.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {}
</script>
<style scoped>
    .icon-camera {
        width: 23%;
      margin-bottom: 2rem;
    }
    .img-back {
        background-image: url("../assets/img-back.png");
    }
    @media screen and (max-width: 1000px) {
        .icon-camera {
            width: 30%;
        }
        .img-back {
            background: url("../assets/img-back.png") no-repeat center center  ;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            width: 100vw;
        }
        .spacingfont{
            letter-spacing: 0.05em;
        }
        .font-14{
            font-size: 14px;
        }
      .font-12{
        font-size: 12px;
      }
        .font-22 {
            font-size: 22px;
        }
    }
</style>

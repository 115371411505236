<template>
    <div id="app">
        <router-view/>
        <div  v-if="
        $route.name != 'Login'">
            <Navigasi/>
        </div>
    </div>
</template>

<style>
    #app {
        font-family: "Poppins", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }
</style>
<script>
    import Navigasi from "./components/Navigasi";

    export default {
        components: {Navigasi},
      // data() {
      //   return {
      //     showUpdateUI: false
      //   };
      // },
      created() {
        if (this.$workbox) {
          this.$workbox.addEventListener("waiting", () => {
            this.showUpdateUI = true;
          });
        }
      },
      methods: {
        async update() {
          this.prompt = false;
          await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        },
      },
      mounted() {
        console.log(process.env.VUE_APP_URL);
      },
    }
</script>

<template>
  <div class="main">
    <div class="col-12 col-md-12 px-0 mx-0 mb-2 mt-2 d-flex">
      <div class="input-group px-0 mx-0 col-9 col-md-10">
        <input
          type="text"
          class="form-control py-4"
          placeholder="Ketik untuk pencarian..."
          v-model="keyword"
          aria-label="Cari disini"
          aria-describedby="basic-addon1"
        />
      </div>

      <div class="col-3 col-md-2 mx-0 px-0">
        <button
          class="col-10 col-md-6 btn btn-theme h-100 float-right"
          @click="searcbar(keyword)"
          type="button"
        >
          <img
            width="20"
            src="https://img.icons8.com/ios/50/000000/search--v1.png"
          />
        </button>
      </div>
    </div>
    <!--    <div class="input-group col-12 col-md-12">-->
    <!--      <input type="text" class="form-control py-4" placeholder="Cari disini" v-model="keyword">-->
    <!--      <div class="input-group-append">-->
    <!--        <button class="btn btn-theme" @click="searcbar(keyword)" type="button">-->
    <!--          <i class="fa fa-search text-white"></i>-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
    };
  },
  methods: {
    // setInterval(this.getNow);
    searcbar() {
      this.$store.dispatch("getOutlet", {
        token: localStorage.token,
        thisPage: 1,
        keyword: this.keyword,
      });
    },
  },
};
</script>

<style scoped>
/*.main{*/
/*  width: 100%;*/
/*  margin-top:15px;*/
/*  margin-bottom:50px;*/
/*}*/
/*.has-search .form-control {*/
/*  padding-left: 2.375rem;*/
/*}*/
/*.has-search .form-control-feedback {*/
/*  position: absolute;*/
/*  z-index: 2;*/
/*  display: block;*/
/*  width: 2.375rem;*/
/*  height: 2.375rem;*/
/*  line-height: 2.375rem;*/
/*  text-align: center;*/
/*  pointer-events: none;*/
/*  color: #aaa;*/
/*}*/
/*.form-control {*/
/*  display: block;*/
/*  width: 100%;*/
/*  height: calc(1.5em + .75rem + 2px);*/
/*  padding: .375rem .75rem;*/
/*  font-size: 1rem;*/
/*  font-weight: 400;*/
/*  line-height: 1.5;*/
/*  color: #495057;*/
/*  background-color: #fff;*/
/*  background-clip: padding-box;*/
/*  border: 1px solid #ced4da;*/
/*  border-radius: .25rem;*/
/*  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;*/
/*}*/
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0px 7px 3px rgba(241, 238, 255, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid rgba(235, 231, 255, 0.7);
}
.btn-theme {
  background-color: #4e37b2;
}
input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 3;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dbdbdb !important;
  opacity: 1; /* Firefox */
}
@media screen and (max-width: 1000px) {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #dbdbdb !important;
    opacity: 1; /* Firefox */
    font-size: 14px;
  }
}
</style>
<template>
    <div class="container space-content">
        <div class="row d-flex justify-content-between mb-5 mx-0">
            <TitleHeader title="Daftar Todo List" />
            <div class="col-12 mt-3 px-0">
                <router-link
                        class="text-white"
                        v-bind:to="'/TodoList/' + `${$route.params.sales_id}/`"
                >
                  <div class="d-flex justify-content-center bg-modal px-0">
                      <img src="../assets/target.png" class="w-icon" alt="" srcset=""/>
                      <div class="d-flex align-items-center col-10 pl-0">
                          <h4
                                  class="text-left font-weight-normal text-purple m-0 font-16 "
                          >
                              Anda mempunyai ({{todo.total}}) outlet list
                          </h4></div>
                  </div>
              </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import TitleHeader from "./TitleHeader";
    export default {
        props: ["todo"],
        components:{
            TitleHeader
        }
    };
</script>

<style scoped>
    .text-purple {
        color: #4e37b2;
        font-weight: 500;
    }

    .bg-modal {
        background: #e5e0ff;
        border-radius: 12px 12px 12px 12px;
    }

    .w-icon {
        width: 20%;
    }

    .space-content {
        padding-top: 2rem;
    }

    @media (max-width: 600px) {
        .space-content {
            padding-top: 0rem;
        }

        .w-icon {
            width: 20%;
        }

        .font-18 {
            font-size: 18px;
        }

        .font-16 {
            font-size: 16px;
        }
    }
</style>

<template>
  <div class="container p-0 color-theme mx-0" style="min-height: 100%">
    <div class="d-flex align-content-center flex-wrap">
      <div class="col-md-12 col-12 h-50 p-0">
        <HeaderLogin />
      </div>
      <div class="col-md-12 col-12 h-50 mx-0 px-0">
        <panelForm @getlogin="getlogin" />
      </div>
    </div>
    <div
      class="d-flex justify-content-center position-fixed divSpinner"
      v-if="isLoading == true"
    >
      <div
        class="spinner-border text-purple position-absolute iconSpinner"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogin from "@/components/HeaderLogin";
import panelForm from "@/components/panelForm";
import axios from "axios";

export default {
  components: { HeaderLogin, panelForm },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async getlogin(username, password) {
      this.isLoading = true;
      const data = {
        username: username,
        password: password,
      };
      try {
        let res = await axios({
          method: "post",
          url: `${process.env.VUE_APP_URL}auth/login`,
          data: data,
        });
        if (res.status === 200) {
          console.log(res.data.data.user_id);
          localStorage.token = res.data.data.token;
          this.$router.push(`DashboardOutlet/${username}`);
        } else {
          alert("Username atau password anda salah");
          window.location.reload();
        }
        return res.data;
      } catch (error) {
        console.error(error, "error");
        console.log(error.response);
        alert(error.response.data.data);
        window.location.reload();
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.iconSpinner {
  top: 50vh;
  z-index: 120;
}
.divSpinner {
  z-index: 100;
  right: 0;
  left: 0;
  top: 0vh;
  background-color: #b684ff;
  min-width: 360px;
  max-width: 750px;
  margin: 0 auto !important;
  min-height: 100vh;
  backdrop-filter: blur(2px);
  opacity: 0.3;
}
.text-purple {
  color: #3d0293 !important;
}
.h-50 {
  min-height: 50vh;
}

.color-theme {
  background-color: #5c27aa;
}
</style>

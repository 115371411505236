<template>
    <div>
        <Header title="Dashboard Outlet" :contentFor="'home'"/>
        <Slider :slider="Slider"/>
        <TodoHeader :todo="Todo.data"/>
        <ListOutlet :contentFor="'DashboardOutlet'" :bulan="Bulan"/>
    </div>
</template>

<script>
    import axios from 'axios'
    import TodoHeader from "../components/TodoHeader.vue";
    import ListOutlet from "../components/ListOutlet.vue";
    import Header from "../components/Header.vue";
    import Slider from "../components/Slider.vue";

    export default {
        components: {Header, Slider, ListOutlet, TodoHeader},
        data() {
            return {
                Slider: {
                    data: []
                },
                Todo: {
                    data: {}
                },
                Bulan: {
                    data: []
                }

            }
        },
        methods: {
            getSlider() {
                axios
                    .get(`${process.env.VUE_APP_URL}banner`, {
                        headers: {
                            Authorization: localStorage.token
                        }
                    })

                    .then(res => (this.Slider = res.data))
                    .catch(err => console.log(err))
            },
            getBulan() {
                axios
                    .get(`${process.env.VUE_APP_URL}bulan`, {})
                    .then(res => (this.Bulan = res.data))
                    .catch(err => console.log(err))
            },
            getTodo() {
                axios
                    .get(`${process.env.VUE_APP_URL}todo`, {
                        headers: {
                            Authorization: localStorage.token
                        }
                    })
                    .then(res => (this.Todo = res.data))
                    .catch(err => console.log(err))
            }

        },
        mounted() {
            this.getSlider();
            this.getTodo();
            this.getBulan();
        }

    };
</script>

<style>
</style>

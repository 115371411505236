<template>
    <div class="container">
        <div class="mb-custom" v-if="contentFor==='home'">
            <nav
                    class="navbar m-auto navbar-Container navbar-light backtheme py-3 d-flex justify-content-center headerstyle"
            >
                <div class="m-auto">
                    <h1 class="font20 fontipad mb-0 text-white text-center">
                        {{ title }}
                    </h1>
                </div>
            </nav>
        </div>
        <div class="mb-custom" v-if="contentFor==='foto'">
            <router-link v-if="contentFor==='foto'" v-bind:to="'/DashboardOutlet/' + `${$route.params.sales_id}`">
                <nav
                        class="navbar m-auto navbar-Container navbar-light backtheme py-3 d-flex justify-content-center footerstyle"
                >
                    <div class="col-md-1 col-1 px-0">
                        <img class="img-back" src="../assets/icon-back.png" alt/>
                    </div>
                    <div class="col-md-11 col-11">
                        <h1 class="font20 fontipad mb-0 text-white text-center">
                            {{ title }}
                        </h1>
                    </div>
                </nav>
            </router-link>
        </div>
      <div class="mb-customSlider" v-if="contentFor==='slider'">
        <nav
            class="navbar m-auto navbar-Container navbar-light backtheme py-3 d-flex justify-content-center headerstyle"
        >
          <div class="m-auto">
            <h1 class="font16 fontipad mb-0 text-white text-center">
              {{ title }}
            </h1>
          </div>
        </nav>
      </div>
    </div>
</template>

<script>
    export default {
        props: ["title", "contentFor"],
        computed: {
            cartItemCount() {
                return this.$store.getters.cartItemCount;
            }
        }
    };
</script>

<style scoped>
    .backtheme {
        background: #5c27aa;
    }

    .headerstyle {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 1030;
    }
.img-back{
    width: 40%;
}
    .navbar-Container {
        max-width: 750px;
    }

    .mb-custom {
        margin-bottom: 6rem;
    }
    .mb-customSlider {
      margin-bottom: 6rem;
    }

    @media screen and (max-width: 1000px) {
        .img-back{
            width: 40%;
        }
        .mb-custom {
            margin-bottom: 5rem;
        }
      .mb-customSlider {
        margin-bottom: 4rem;
      }
        .footerstyle {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1030;
        }

        .font20 {
            font-size: 20px;
        }
      .font16 {
        font-size: 16px;
      }

    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      .navbar-Container {
        max-width: 100vw;
      }

      .fontipad {
        font-size: 30px;
      }
    }
</style>

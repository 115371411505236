<template>
  <div class="container">
    <div v-if="contentFor === 'DashboardOutlet'" class="space-content">
      <div class="row d-flex justify-content-between">
        <div class="col">
          <h3 class="text-purple font-18 text-left my-0 py-0">Daftar Outlet</h3>
          <p class="text-left text-purple font-14">
            Pilih outlet untuk dimonitoring
          </p>
        </div>
        <div class="dropdown px-3">
          <button
            class="btn bg-purple text-right font-12 dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ outlets.bulan }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <div v-for="item in bulan.data" :key="item.id">
              <button
                class="dropdown-item"
                type="button"
                @click="getDataBulan(item.id)"
              >
                {{ item.bulan }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="content m-0 col-md-12 px-0">
        <table class="table px-0 mb-1 mx-0">
          <thead>
            <tr>
              <th scope="col px-0 ">
                <button
                  @click="selected = null"
                  type="button"
                  class="btn font-custom rounded-pill px-0 btn-menu"
                >
                  <h5 class="font14 mb-0 font-weight-normal px-3">All</h5>
                </button>
              </th>
              <th scope="col px-0">
                <button
                  @click="selected = 'Diterima'"
                  type="button"
                  class="btn font-custom rounded-pill px-0 btn-menu"
                >
                  <h5
                    class="font14 mb-0 font-weight-normal px-3"
                    style="white-space: nowrap"
                  >
                    Diterima
                  </h5>
                </button>
              </th>
              <th scope="col px-0">
                <button
                  @click="selected = 'Upload'"
                  type="button"
                  class="btn font-custom rounded-pill btn-menu"
                >
                  <h5
                    class="font14 mb-0 font-weight-normal px-2"
                    style="white-space: nowrap"
                  >
                    Upload
                  </h5>
                </button>
              </th>
              <th scope="col px-0">
                <button
                  @click="selected = 'Ditolak'"
                  type="button"
                  class="btn font-custom rounded-pill btn-menu"
                >
                  <h5
                    class="font14 mb-0 font-weight-normal px-2"
                    style="white-space: nowrap"
                  >
                    Ditolak
                  </h5>
                </button>
              </th>
              <th scope="col px-0">
                <button
                  @click="selected = 'Belum Foto'"
                  type="button"
                  class="btn font-custom rounded-pill btn-menu"
                >
                  <h5
                    class="font14 mb-0 font-weight-normal px-2"
                    style="white-space: nowrap"
                  >
                    Belum Foto
                  </h5>
                </button>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <SearchBar />
      <ItemOutlet
        v-for="outlet in selectTab"
        :isLoading="isLoading"
        :key="outlet.id"
        :content-for="'list'"
        :outlet="outlet"
      />
      <div class="d-flex">
        <div class="col-md-2 offset-md-4 px-0">
          <button
            type="button"
            :disabled="thisPage <= 1"
            @click="thisPage -= 1"
            class="btn bg-purple text-white py-1 mt-5 mb-0 px-4"
          >
            Back
          </button>
        </div>
        <div class="col-md-2 px-0">
          <button
            type="button"
            :disabled="thisPage >= outlets.totalPage"
            @click="thisPage += 1"
            class="btn bg-purple text-white py-1 mt-5 mb-0 px-4"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div v-if="contentFor === 'TodoList'">
      <TitleHeader
        title="Daftar Outlet"
        caption=" Pilih outlet untuk dimonitoring."
      />

      <ItemOutlet
        v-for="outlet in todos"
        :content-for="'todo'"
        :key="outlet.id"
        :outlet="outlet"
      />
    </div>
    <div v-if="contentFor === 'Notif'" class="mb-notif">
      <TitleHeader
        title="Daftar Notification anda"
        caption="Berikut adalah notification status list outlet"
      />

      <ItemOutlet
        v-for="notif in notifs.data"
        :content-for="'Notif'"
        :key="notif.id"
        :notif="notif"
      />
    </div>
  </div>
</template>
<script>
import SearchBar from "./SearchBar";
import ItemOutlet from "./ItemOutlet";
import TitleHeader from "./TitleHeader";

export default {
  props: ["contentFor", "bulan"],
  data() {
    return {
      selected: null,
      alltransaction: {
        data: [],
      },
      currenttime: "",
      isLoading: false,
      thisPage: 1,
    };
  },

  components: { TitleHeader, ItemOutlet, SearchBar },
  watch: {
    thisPage() {
      if (this.thisPage) {
        this.getDataBulan();
      }
    },
  },
  methods: {
    getDataBulan(bulan) {
      this.isLoading = true;
      this.$store.dispatch("getOutlet", {
        token: localStorage.token,
        bulan: bulan,
        thisPage: this.thisPage,
      });
      this.isLoading = false;
    },
    getNow: function () {
      const today = new Date();

      const date = today.getMonth() + 1;

      this.currenttime = date;
      console.log(date);
    },
  },
  computed: {
    outlets() {
      return this.$store.state.outlets;
    },
    selectTab() {
      if (this.selected == null) {
        return this.outlets.data;
      } else {
        return this.outlets.data.filter((x) => x.jenis === this.selected);
      }
    },
    todos() {
      console.log(this.$store.state.todos);
      return this.$store.state.todos;
    },
    notifs() {
      return this.$store.state.notifs;
    },
  },
  mounted() {
    // setInterval(this.getNow);
    this.$store.dispatch("getTodo", {
      token: localStorage.token,
    });
    this.$store.dispatch("getOutlet", {
      token: localStorage.token,
      show: this.show,
      thisPage: this.thisPage,
    });
    this.getDataBulan();
    this.$store.dispatch("getNotif", {
      token: localStorage.token,
    });
  },
};
</script>
<style scoped>
.bg-purple {
  background-color: #4e37b2;
  color: #ffffff;
  box-shadow: 2px 4px 12px rgba(78, 55, 178, 0.35);
}

.space-content {
  padding-top: 2rem;
  margin-bottom: 8rem;
}

.bg-modal {
  background: #5c27aa;
  color: #ffffff;
}

.text-purple {
  color: #4e37b2;
}

.shadow-item {
  box-shadow: 0 0.1rem 0.8rem rgba(150, 142, 255, 0.15) !important;
}

.btn-foto {
  background: #21bcaa;
  color: #ffffff;
}

.btn-add {
  background: #ff7750;
  color: #ffffff;
}

.btn-menu {
  color: #4e37b2;
  background-color: #e5e0ff;
}

.btn-menu:hover {
  background-color: #4e37b2;
  border-color: #4e37b2;
  color: white;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
}

.imagecustom {
  width: 6%;
}

.mb-notif {
  margin-bottom: 6rem;
}
@media screen and (max-width: 1000px) {
  .space-content {
    padding-top: 0rem;
    margin-bottom: 6rem;
  }
  .mb-notif {
    margin-bottom: 8rem;
  }
  .content {
    /* This is the magic bit for Firefox */
    overflow-x: scroll;
    scrollbar-width: none;
    max-width: 100%;
  }

  .content::-webkit-scrollbar {
    /* This is the magic bit for WebKit */
    display: none;
  }

  .font14 {
    font-size: 14px;
  }

  .font-18 {
    font-size: 18px;
  }

  .w-icon {
    width: 20px;
  }

  .dropdown-menu {
    min-width: 7rem;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .imagecustom {
    background-size: cover;
    height: 33px;
    width: 9%;
  }
}
</style>

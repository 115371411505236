<template>
  <div>
    <div v-if="contentFor === 'todo'">
      <router-link
        class="text-white"
        v-bind:to="'/Photos/' + `${$route.params.sales_id}/` + outlet.outlet_id"
      >
        <div class="col-12 col-md-12 m-0 px-0 shadow-item">
          <div
            class="
              text-left
              border
              rounded
              d-flex
              flex-row
              align-items-center
              my-3
              py-3
              px-3
            "
          >
            <img class="imagecustom" src="../assets/store-icon.png" alt />
            <div class="align-items-center text-center col-md-8 col-8">
              <h5
                class="
                  font14
                  caption4
                  p-0
                  m-0
                  font-weight-normal
                  text-purple text-left
                "
                data-toggle="modal"
                :data-target="'#todo' + outlet.outlet_id"
              >
                {{ outlet.outlet_id }}
              </h5>
            </div>
            <div class="col text-center px-3">
              <img
                class="w-icon"
                :src="require('../assets/' + outlet.icon)"
                alt
              />
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div v-if="contentFor === 'Notif'">
      <div
        class="col-12 col-md-12 m-0 px-0 shadow-item"
        @click="takeFoto(notif.outlet_id)"
      >
        <div
          class="
            text-left
            border
            rounded
            d-flex
            flex-row
            align-items-center
            my-3
            py-3
            px-3
          "
        >
          <div class="col-2 col-md-3 px-0">
            <img
              class="w-auto mx-auto d-flex justify-content-center"
              src="../assets/store-icon.png"
              alt
            />
          </div>
          <div class="px-2 col-10 col-md-9 d-flex flex-column">
            <div class="col-12">
              <h5
                class="
                  font14
                  caption4
                  px-0
                  pb-0
                  m-0
                  font-weight-normal
                  text-purple text-left
                "
              >
                {{ notif.outlet_id }}
              </h5>
            </div>
            <div class="col-12">
              <p class="font-12 float-left mb-0">{{ notif.tanggal }}</p>
            </div>
            <div class="col-12 mt-2">
              <p
                :class="{
                  'text-center font-12 py-1 m-0 px-3 font-weight-normal text-white rounded-pill badge bg-danger float-left':
                    notif.status === 'Foto Ditolak',
                  'text-center font-12  py-1 m-0 px-3 font-weight-normal text-white rounded-pill badge bg-success float-left':
                    notif.status === 'Foto Diterima',
                }"
              >
                {{ notif.status }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="contentFor === 'list'">
      <div
        class="d-flex justify-content-center position-fixed divSpinner"
        v-if="isLoading == true"
      >
        <div
          class="spinner-border text-purple position-absolute iconSpinner"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-if="bulanActive.bulan === outlet.bulan">
        <div
          class="col-12 col-md-12 m-0 px-0 shadow-item"
          v-if="outlet.jenis != 'Diterima'"
        >
          <div
            class="
              text-left
              border
              rounded
              d-flex
              flex-row
              align-items-center
              my-3
              py-3
              px-3
            "
            data-toggle="modal"
            :data-target="'#todo' + outlet.outlet_id.replace(/[./-]/g, '')"
          >
            <img class="imagecustom" src="../assets/store-icon.png" alt />
            <div class="align-items-center text-center col-md-8 col-8">
              <h5
                class="
                  font14
                  caption4
                  p-0
                  m-0
                  font-weight-normal
                  text-purple text-left
                "
              >
                {{ outlet.outlet_id }}
              </h5>
              <h5
                class="
                  font14
                  caption4
                  p-0
                  m-0
                  font-weight-normal
                  text-purple text-left
                "
              >
                {{ outlet.outlet_name }}
              </h5>
            </div>
            <div class="col text-center px-3">
              <img
                class="w-icon"
                :src="require('../assets/' + outlet.icon)"
                alt
              />
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-12 m-0 px-0 shadow-item"
          v-if="outlet.jenis == 'Diterima'"
        >
          <div
            class="
              text-left
              border
              rounded
              d-flex
              flex-row
              align-items-center
              my-3
              py-3
              px-3
            "
            data-toggle="modal"
            @click="takeFoto(outlet.outlet_id)"
          >
            <img class="imagecustom" src="../assets/store-icon.png" alt />
            <div class="align-items-center text-center col-md-8 col-8">
              <h5
                class="
                  font14
                  caption4
                  p-0
                  m-0
                  font-weight-normal
                  text-purple text-left
                "
              >
                {{ outlet.outlet_id }}
              </h5>
              <h5
                class="
                  font14
                  caption4
                  p-0
                  m-0
                  font-weight-normal
                  text-purple text-left
                "
              >
                {{ outlet.outlet_name }}
              </h5>
            </div>
            <div class="col text-center px-3">
              <img
                class="w-icon"
                :src="require('../assets/' + outlet.icon)"
                alt
              />
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          :id="'todo' + outlet.outlet_id.replace(/[./-]/g, '')"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header justify-content-center bg-modal">
                <h5 class="modal-title">
                  {{ outlet.outlet_id }}
                </h5>
              </div>
              <div class="modal-body text-center">
                Silahkan pilih action yang ingin dilakukan pada outlet ini :
              </div>
              <div class="modal-footer justify-content-center">
                <button
                  type="button"
                  class="btn btn-add"
                  @click="addTodo(outlet.outlet_id)"
                >
                  Add Todo
                </button>
                <button
                  type="button"
                  class="btn btn-foto"
                  @click="takeFoto(outlet.outlet_id)"
                >
                  Ambil Foto
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="col-12 col-md-12 m-0 px-0 shadow-item" @click="cekBulan">
          <div
            class="
              text-left
              border
              rounded
              d-flex
              flex-row
              align-items-center
              my-3
              py-3
              px-3
            "
          >
            <img class="imagecustom" src="../assets/store-icon.png" alt />
            <div class="align-items-center text-center col-md-8 col-8">
              <h5
                class="
                  font14
                  caption4
                  p-0
                  m-0
                  font-weight-normal
                  text-left text-purple
                "
                data-toggle="modal"
              >
                {{ outlet.outlet_id }}
              </h5>
            </div>
            <div class="col text-center px-3">
              <img
                class="w-icon"
                :src="require('../assets/' + outlet.icon)"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";

export default {
  props: ["outlet", "contentFor", "notif", "isLoading"],
  data() {
    return {
      bulanActive: {},
      jenis: "ww",
    };
  },
  methods: {
    async addTodo(outlet_id) {
      // // eslint-disable-next-line no-undef
      // $('#todo' + outlet_id).modal('hide')
      const data = {
        outlet_id: outlet_id,
      };
      try {
        let res = await axios({
          method: "post",
          url: `${process.env.VUE_APP_URL}todo`,
          data: data,
          headers: {
            Authorization: localStorage.token,
          },
        });
        if (res.status === 201) {
          this.$router.push(`/TodoList/${this.$route.params.sales_id}`);
          $("#todo" + outlet_id).modal("hide");
        } else {
          alert("Data sudah ada");
          // eslint-disable-next-line no-undef
          $("#todo" + outlet_id).modal("hide");
          window.location.reload();
        }
        return res.data;
      } catch (error) {
        console.log(error.response);
        alert("Data sudah terverifikasi");
      }
    },
    getBulanActive() {
      axios
        .get(`${process.env.VUE_APP_URL}bulan/active`)
        .then((res) => (this.bulanActive = res.data))
        .catch((err) => console.log(err));
    },
    takeFoto(outlet_id) {
      localStorage.setItem("outlet_id", outlet_id);
      // let encoded = encodeURIComponent(outlet_id);
      // let decode = decodeURIComponent(encoded);

      this.$router.push(`/Photos/${this.$route.params.sales_id}`);
      window.location.reload();
      $("#todo" + outlet_id).modal("hide");
    },
    cekBulan() {
      alert("Upload sesuai bulan yang sekarang");
    },
    getJenis() {
      if (this.outlet.jenis != "Completed") {
        // console.log((this.jenis = this.outlet.outlet_id));
        return (this.jenis = this.outlet.outlet_id);
      } else {
        return `this.$router.push(\`/Photos/${this.$route.params.sales_id}/\` + outlet_id);`;
      }
    },
  },
  mounted() {
    this.getBulanActive();
    this.getJenis();
  },
  computed: {},
};
</script>
<style scoped>
/deep/ .modal-backdrop {
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 0vw !important;
  height: 0vh !important;
  background-color: #000;
}
.iconSpinner {
  top: 50vh;
  z-index: 120;
}
.divSpinner {
  z-index: 100;
  right: 0;
  left: 0;
  top: 0vh;
  background-color: #b684ff;
  min-width: 360px;
  max-width: 750px;
  margin: 0 auto !important;
  min-height: 100vh;
  backdrop-filter: blur(2px);
  opacity: 0.3;
}
.text-purple {
  color: #3d0293 !important;
}
.shadow-item {
  box-shadow: 0 0.1rem 0.8rem rgba(150, 142, 255, 0.15) !important;
}

.imagecustom {
  width: 6%;
}

.text-purple {
  color: #4e37b2;
}

.shadow-item {
  box-shadow: 0 0.1rem 0.8rem rgba(150, 142, 255, 0.15) !important;
}

.btn-foto {
  background: #21bcaa;
  color: #ffffff;
}

.btn-add {
  background: #ff7750;
  color: #ffffff;
}

.bg-modal {
  background: #5c27aa;
  color: #ffffff;
}

.imagecustom {
  width: 6%;
}

@media screen and (max-width: 1000px) {
  .font14 {
    font-size: 14px;
  }
  .font-12 {
    font-size: 12px;
  }
  .w-icon {
    width: 55%;
  }

  .imagecustom {
    background-size: cover;
    height: 33px;
    width: 9%;
  }
}
</style>

import axios from "axios";

export function getOutlet({ commit }, { token, bulan, thisPage, keyword }) {
  axios
    .get(`${process.env.VUE_APP_URL}outlet`, {
      headers: {
        Authorization: token,
      },
      params: {
        bulan: bulan,
        page: thisPage,
        keyword: keyword,
      },
    })
    .then((response) => {
      commit("SET_OUTLET", response.data.data);
    });
}

export function getTodo({ commit }, { token }) {
  axios
    .get(`${process.env.VUE_APP_URL}todo`, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      commit("SET_TODO", response.data.data.data);
    });
}

export function getImage({ commit }, { token, outlet_id }) {
  axios
    .get(`${process.env.VUE_APP_URL}outlet/image/`, {
      headers: {
        Authorization: token,
      },
      params: {
        outlet_id: outlet_id,
      },
    })
    .then((response) => {
      commit("SET_IMAGE", response.data.data.data);
    });
}
export function getNotif({ commit }, { token }) {
  axios
    .get(`${process.env.VUE_APP_URL}notification/`, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      commit("SET_NOTIF", response.data.data);
    });
}

<template>
    <div class="container mx-0 px-0 position-absolute" style="bottom: 0">
        <div class="col-md-12 col-12 mx-0 px-0 ">
            <div class="box-login ">
                <div class="mx-4 pt-5 h-login">
                    <div>
                        <h1 class="color-theme font-20px text-left mb-1">
                            Selamat Datang,
                        </h1>
                        <p class="color-theme font-14px  text-left">
                            Silahkan login untuk melanjutkan
                        </p>
                    </div>
                    <div class="login-content mt-4">
                        <form>
                            <div class="input-div mx-0">
                                <div
                                        class=" d-flex justify-content-center align-items-center"
                                >
                                    <img class="height-icon" src="../assets/user-login.png"/>
                                </div>
                                <div class="div">
                                    <input placeholder="Username" type="text" class="input font-18px " v-model="username"/>
                                </div>
                            </div>
                            <div class="input-div mx-0 mt-3">
                                <div
                                        class=" d-flex justify-content-center align-items-center"
                                >
                                    <img class="height-icon" src="../assets/user-password.png"/>
                                </div>
                                <div class="div">
                                    <input placeholder="Password" type="password" class="input font-18px " v-model="password"/>
                                </div>
                            </div>
                            <button @click="getlogin" type="button" class="btn text-white mt-5">
                                <h5 class="mb-0 font-weight-normal"> Masuk</h5>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "panelForm",
        data() {
            return {
                username: "",
                password: ""
            }
        },
        methods: {
            getlogin() {
                this.$emit("getlogin", this.username, this.password)
            }
        }

    };
</script>
<style scoped>

    .login-content .input-div {
        position: relative;
        display: grid;
        grid-template-columns: 7% 93%;
        margin: 25px 0;
        padding: 5px 0;
        border-bottom: 2px solid #d9d9d9;
    }

    ::placeholder {
        color: rgba(78, 55, 178, 0.5);
    }

    .input-div > div {
        position: relative;
        height: 45px;
    }

    .input-div > div > input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: none;
        padding: 0.5rem 0.7rem;
        font-size: 1.2rem;
        color: #555;
        font-family: "poppins", sans-serif;
    }

    .btn {
        display: block;
        width: 100%;
        height: 50px;
        border-radius: 16px;
        background-image: linear-gradient(to right, #4e37b2, #4e37b2, #4e37b2);
        cursor: pointer;
        box-shadow: 2px 4px 12px rgba(78, 55, 178, 0.35);
        letter-spacing: 0.1em;
    }

    .box-login {
        background-color: white;
        box-shadow: 0px -2px 20px 2px rgba(255, 255, 255, 0.1);
        border-radius: 40px 40px 0px 0px;
    }

    .color-theme {
        color: #5a3ea4;
    }

    .h-login {
        min-height: 45vh;
    }

    @media only screen and (max-width: 1000px) {
        .h-login {
            min-height: 60vh;
        }

        .font-20px {
            font-size: 20px;
        }
      .font-14px {
        font-size: 14px;
      }
      .font-18px {
        font-size: 18px !important;
      }
        .height-icon {
            width: auto;
            height: 60%;
        }
    }
</style>


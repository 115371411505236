export const SET_OUTLET = (state, outlets) => {
    state.outlets = outlets
}
export const SET_TODO = (state, todos) => {
    state.todos = todos
}
export const SET_IMAGE = (state, images) => {
    state.images = images
}
export const SET_NOTIF = (state, notifs) => {
    state.notifs = notifs
}

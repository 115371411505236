<template>
    <div>
        <Header title="Todo List - Outlet" :contentFor="'foto'"/>
      <TodoHeader :todo="Todo.data"/>
        <ListOutlet :contentFor="'TodoList'"/>
    </div>
</template>

<script>
    import TodoHeader from "../components/TodoHeader.vue";
    import ListOutlet from "../components/ListOutlet.vue";
    import Header from "../components/Header.vue";
    import axios from 'axios'

    export default {
        components: {Header, ListOutlet, TodoHeader},
        data() {
            return {
                Todo: {
                    data: {}
                }
            }
        },
        methods: {
            getTodo() {
                axios
                    .get(`${process.env.VUE_APP_URL}todo`, {
                        headers: {
                            Authorization: localStorage.token
                        }
                    })
                    .then(res => (this.Todo = res.data))
                    .catch(err => console.log(err))
            }
        },
        mounted() {
            this.getTodo();
        }
    };
</script>

<style>
</style>

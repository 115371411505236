<template>
    <div class="container px-0">
        <div class="row d-flex justify-content-between">
            <div class="col">
                <h3 class=" text-purple font-18 text-left my-0 py-0">
                    {{title}}
                </h3>
                <p class="text-purple text-left font-12 mb-0">
                    {{caption}}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ["title", "caption"],
    }

</script>
<style scoped>
    .text-purple {
        color: #4e37b2;
    }

    @media screen and (max-width: 1000px) {
        .font-18 {
            font-size: 18px;
        }

        .font-12 {
            font-size: 12px;
        }
    }
</style>

<template>
  <div class="col-md-12 col-12 p-0 mb-5 ">
    <div class="mx-3 rounded-slider" v-if="slider.data.length > 0">
      <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings">
        <div class="" v-for="(list, index) in slider.data" :key="index">
          <img @click="details(list.id)" :src="list.foto" alt="" class="w-100  rounded-img" />
          <div class="row d-flex flex-column px-4 mt-3">
            <h4 class="text-left font-15 mb-1">{{ list.judul }}</h4>
            <p class="text-left font-12">{{ list.deskripsi }}</p>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Slider",
  props: {
    slider: Object
  },
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: "linear"
      }
    };
  },
  methods:{
    details(id){
      this.$router.push(
          `/Slider/`+ `${this.$route.params.sales_id}/`+id
      )
    }
  }
};
</script>

<style scoped>
.rounded-slider {
  border: 1px solid #dedede;
  border-radius: 10px;
}
/deep/.slick-prev {
  left: 6px;
  z-index: 10;
}
/deep/.slick-next {
  right: 6px;
  z-index: 10;
}
.rounded-img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 1000px) {
  .space-content {
    padding-top: 0rem;
  }

  .font-15 {
    font-size: 15px;
  }

  .font-12 {
    font-size: 12px;
  }
}
</style>

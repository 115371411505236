<template>
  <div class="container">
    <Header title="PANDUAN IMPLEMENTASI MBD" :contentFor="'slider'" />
    <div class="row mx-0 justify-content-between">
      <div class="col-md-4 col-3 px-0 py-1 panelBox">
        <p class="my-1 text-dark font-13">
          TYPE: <b>{{ Slider.data.jenis }}</b>
        </p>
      </div>
      <div class="col-md-7 col-8 px-0 py-1 panelBox">
        <p class="my-1 text-dark font-13">
          REWARD PER BULAN: <b>{{ Slider.data.reward }}</b>
        </p>
      </div>
    </div>
    <div class="panelBox py-4 mt-3 px-4">
      <img class="img-fluid w-100" :src="Slider.data.gambar" alt="promo" />
    </div>

    <div class="mt-3 mb-5 px-2 panelBox px-1 py-4">
      <h6 class="text-center font-weight-bold mb-3">Syarat & Ketentuan</h6>
      <div>
        <div
          class="mb-1 font-12"
          v-for="sk in Slider.data.data"
          :key="sk.index"
        >
          <img class="img-fluid w-100" :src="sk.sk" alt="sk" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import axios from "axios";
export default {
  components: { Header },
  data() {
    return {
      Slider: {},
    };
  },
  methods: {
    getDetail() {
      axios
        .get(
          `${process.env.VUE_APP_URL}jenis/syarat/${this.$route.params.id}`,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((res) => (this.Slider = res.data))
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>

<style scoped>
.panelBox {
  border-radius: 10px;
  background-color: #f4efef;
}
@media screen and (max-width: 1000px) {
  .font-13 {
    font-size: 13px;
  }
  .font-14 {
    font-size: 14px;
  }
  .font-12 {
    font-size: 12px;
  }
}
</style>
